/*! `Custom` Bootstrap 5 theme */

//$enable-grid-classes:false;
// Theme colors
$primary: #466EE8;           //main action buttons, checkbox bgs etc.
$secondary: #1F2C41;         //status bg color for "no version"
$success: #238835;           //status bg color for "latest" revision
$warning: #FFEBC1;           //status bg color for "earlier" revision
$danger: #E34A4A;            //bg color of delete btn
$info: #E9F4FC;              //bg color of informative alert box
$light: #08080D;             //bg color of top nav bar
$dark: #1C1C21;              //bg color of sub nav bar
// Theme colors end

$body-bg: #F7F7FA;
$body-color: #334334;
$border-color: #E8E8E8;

//Form controls
$input-border-color: #CED4DA;
$input-disabled-bg: #e9ecef;
//Form controls end

//Text colors
$heading-color: #4B4257;       //heading color of the side bar elements
$list-color: $heading-color;
$active-color: #D5E9FF;      //bg of selected storage, pagination page
$info-text-color: #776B85;   //descriptions for storage, factories, element code, results count etc.

//Tabs
$nav-tabs-link-active-bg: $active-color;

.nav-tabs {
    .nav-item {
        .nav-link {
            font-size: 0.875rem;
            font-weight: 550;
            font-variation-settings: "wght" 550;
            color: $heading-color !important;
            line-height: 1.2;
        }

        .nav-link:hover {
            cursor: pointer;
            background-color: rgba($active-color, 0.5);
        }
    }
}
//Tabs end

// Table values
$table-striped-bg-factor: .02;
$table-hover-bg-factor: 0;
$table-header-bg-light: #E8E8EF;
$table-header-text-color: heading-color;
$table-body-text-color: #776B85;
//Table values end


$button-disabled-bg: #B4B4BB;
$button-border: transparent;

// Custom colors
$highlight-text-color: #0049C1;
$highlight-bg-color: $active-color;
$custom-light: #f2f2f2;     //text color for dark bg, i.e. "published" bg
$modal-header-color: $primary;

// Pagination colors
$pagination-bg: white;
$dropdown-bg: white;
$dropdown-link-hover-bg: rgba($active-color, 0.5);
$pagination-active-bg: $active-color;
$pagination-hover-bg: rgba($active-color, 0.5);
$pagination-focus-bg: $active-color;
$pagination-color: $list-color;
$pagination-active-color: $list-color;
// Pagination colors end

// Modal colors
$modal-content-bg:$body-bg;
// Modal colors end

/*! Import Bootstrap 5 variables */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Navigation styles start
$navbar-light-text-color: #8A8A8A;              //top nav bar
$navbar-light-text-color-hover: #AAAAAA;        //top nav bar
$navbar-light-text-color-active: #CACACA;       //top nav bar

$navbar-dark-text-color: #8A8A8A;               //sub nav bar
$navbar-dark-text-color-hover: #AAAAAA;         //sub nav bar
$navbar-dark-text-color-active: #CACACA;        //sub nav bar

$navbar-dark-bg-color-hover: #26262B;       //sub nav bar
$navbar-dark-bg-color-active: #303035;      //sub nav bar
// Navigation styles end

// Font family
@font-face {
    font-family: 'roboto_flex';
    src: url('assets/fonts/RobotoFlex-VariableFont.ttf');
    font-weight: 100 900;
}
$font-family-base: roboto_flex, sans-serif; // Uncomment if you want to use the roboto flex in whole body

//Main navbar start
.logo {
    height: 30px;
    margin-right: 0.5rem;
    font-variation-settings: 'wght' 900 !important;
}

.navbar {
    font-family: roboto_flex, sans-serif; // Roboto flex used only in top navigation bars
    font-weight: 500;
    font-variation-settings: "wght" 500;
    font-size: 0.875rem;

    .dropdown {
        list-style: none;
    }
}

.nav-link {
    color: $navbar-light-text-color !important;
}
.nav-link:hover {
    color: $navbar-light-text-color-hover !important;
}
.nav-link.active {
    color: $navbar-light-text-color-active !important;
}

//logo
.navbar-brand {
    font-weight: 400;
    font-variation-settings: "wght" 400;
    color: #00A4FF !important;
    font-size: 1.0rem !important;
    display: flex;
    align-items: center;

    .logo {
        filter: invert(53%) sepia(28%) saturate(6147%) hue-rotate(174deg) brightness(98%) contrast(111%);
    }
}
//Main navbar end

//Generic icons
.icon {
    box-sizing: content-box;
}
.icon-alert-dialog {
    height: 24px;
}
.icon-export {
    width: 16px;
    height: 16px;
}
.icon-modal {
    height: 18px;
}
.icon-time {
    height: 14px;
    opacity: 0.8;
}

.icon-sidebar-header {
    width: 24px;
    height: 24px;
}
.icon-sidebar, .icon-table {
    width: 16px;
    height: 16px;

    &.owner {
        filter: invert(55%) sepia(31%) saturate(546%) hue-rotate(57deg) brightness(100%) contrast(93%); //438823
    }

    &.write, &.stage, &.prod {
        filter: invert(63%) sepia(41%) saturate(526%) hue-rotate(170deg) brightness(99%) contrast(87%); //71B1ED
    }

    &.readonly {
        filter: invert(55%) sepia(35%) saturate(564%) hue-rotate(287deg) brightness(95%) contrast(88%); //D47798
    }

    &.locked {
    }
}
//Generic icons end

//Generic tags
.highlightTag {
    padding: 2px 6px !important;
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    width: unset !important;
    height: unset !important;
    display: inline-block;

    &.within1d {
        background: #AF67ED;
        color: #ffffff !important;
    }

    &.within7d {
        background: #7767ED;
        color: #ffffff !important;
    }

    &.pos {
        background: #4799E6;
        color: #ffffff !important;
    }

    &.neutral {
        background: #CC8500;
        color: #ffffff !important;
    }

    &.neg {
        background: #D17A99;
        color: #ffffff !important;
    }
}

//Generic tags end
.navbar {
    .nav-icon {
        filter: invert(59%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(93%) contrast(87%);
    }

    .nav-icon {
        height: 16px;
        align-self: center;

        &.nav-user {
            height: 20px;
        }
    }

    .navbar-toggler-icon {
        filter: brightness(0) invert(1);
    }
}


.secondaryLabel {
    font-size: 0.8125rem;
    color: rgba($body-color, 0.75);
}

.custom_dropdown__option--is-selected {
    .secondaryLabel {
        color: inherit;
    }
}

//Sub nav bar
.navbar-sub {
    font-weight: 500;
    font-variation-settings: "wght" 500;
    font-size: 0.875rem;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .navbar-nav {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .nav-link {
        text-align: center;
        padding: 0.75rem 1rem !important;
        background-color: transparent;
        color: $navbar-dark-text-color !important;
        white-space: nowrap;

        .nav-icon {
            filter: invert(59%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(93%) contrast(87%);
        }
    }

    .nav-icon {
        height: 16px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .nav-link:hover {
        background-color: $navbar-dark-bg-color-hover;
        color: $navbar-dark-text-color-hover !important;
        transition: background-color 0.15s ease;

        .nav-icon {
            filter: invert(88%) sepia(0%) saturate(295%) hue-rotate(178deg) brightness(78%) contrast(89%);
        }
    }

    .nav-link.active {
        background-color: $navbar-dark-bg-color-active;
        color: $navbar-dark-text-color-active !important;
        transition: background-color 0.15s ease;

        .nav-icon {
            filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(138deg) brightness(99%) contrast(75%);
            transition-duration: 0s;
        }
    }
}
//Sub nav bar end

// Side bar
$list-group-bg: #ffffff;

//general list group item
$list-group-color: $list-color;

//disabled list group item
$list-group-disabled-bg: $list-group-bg;

//hover list group item
$list-group-hover-color: $warning;
$list-group-hover-bg: rgba($active-color, 0.5);

//active list group item
$list-group-active-color: $list-color;
$list-group-active-bg: $active-color;

//fix to resolve an active listitem shifting items vertically in the container due to changing top margin
.list-group-item.active {
    margin-top: 0px !important;
}

.list-group-item-heading {
    font-size: 0.8125rem;
    font-weight: 550;
    font-variation-settings: "wght" 550;
    color: $heading-color;
    line-height: 1.2;
    overflow: hidden;
}

.list-group-item-text {
    font-size: 0.8125rem;
    font-weight: 400;
    font-variation-settings: "wght" 400;
    color: $info-text-color;
    line-height: 1.2;
}


input[type="checkbox"],
input[type="checkbox"] + label,
input[type="radio"],
input[type="radio"] + label {
    cursor: pointer;
}

div.checkboxlist:not(.modal *),
.list-group {
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid $border-color;
}

div.checkboxlist {
    width: auto;

    .form-check-label {
        width: 100%;
        display: block;
    }
}

.form-check:hover:not(.disabled) {
    .list-group-item-heading {
        color: rgba($heading-color, 0.75);
    }

    .list-group-item-text {
        color: rgba($info-text-color, 0.75);
    }

    .form-check-label:not(.disabled) {
        color: rgba($body-color, 0.75);
        cursor: pointer;
    }

    //cursor: pointer;
}

.iconList {
    line-height: 1.2;
    display: flex;
    align-items: center;
}
.factorySymbol {
    font-size: 14px;
}

// Side bar end
// Headings
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.2;
$h3-font-size: $font-size-base * 1.1;
$h4-font-size: $font-size-base * 1.1;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;
$headings-font-weight: 500;

h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
}

.heading-info {
    font-size: 0.8125rem;
    font-weight: 400;
    font-variation-settings: "wght" 400;
    padding: 8px;
    border-radius: 4px;
    background-color: #ECECEF;
    opacity: 0.9;
}

.modal-title .heading-info {
    background-color: unset;
}

.content {
    font-size: 0.8125rem;
    color: $info-text-color;
}

.font-size-small {
    font-size: 0.8125rem;
}
// Headings end
// Common check box
$form-check-input-width: 1.375rem;

.form-check-input {
    margin-top: 0px !important;
    &.danger:checked {
        background-color: $danger;
        border-color: $danger;
    }
}
// Common check box end

.cursor-pointer{
    cursor: pointer;
}
//@import "bootstrap";
// Bootstrap Layout and components
//@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
//@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
//@import "../node_modules/bootstrap/scss/card";
//@import "../node_modules/bootstrap/scss/accordion";
//@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
//@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
//@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
//@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
//@import "../node_modules/bootstrap/scss/offcanvas";
//@import "../node_modules/bootstrap/scss/placeholders";
// Add SASS theme customizations here..
// Buttons
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        border-color: $button-border;
        color: $white;
    }

    .btn-#{$color}:disabled, .btn-#{$color}.disabled {
        background-color: $button-disabled-bg;
        border-color: $button-border;
        color: $white;
    }

    .btn-#{$color}:hover, .btn-#{$color}:active, .btn-#{$color}:focus, .btn-outline-#{$color}:hover, .btn-outline-#{$color}:active {
        color: $white !important;
    }
}

.btn-toolbar {
    row-gap: 0.25rem;
}

.btn {
    img.icon {
        height: 18px;
        filter: brightness(0) invert(1);

        &.icon-with-text {
            height: 16px;
            margin-right: 0.25rem;
        }

        &.dark {
            filter: none;
        }
    }

    &.btn-xs {
        img.icon {
            height: 15px;
            margin-bottom: 5px;

            &.icon-width {
                width: 12px;
            }
        }
    }

    &.action {
        filter: invert(68%) sepia(75%) saturate(6942%) hue-rotate(213deg) brightness(94%) contrast(93%);
    }

    &.danger {
        filter: invert(65%) sepia(70%) saturate(6589%) hue-rotate(333deg) brightness(92%) contrast(92%);
    }

    &.no-background {
        img.icon {
            filter: invert(60%) sepia(4%) saturate(15%) hue-rotate(314deg) brightness(91%) contrast(92%);
        }
    }
}

.btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.btn-xs {
    height: 25px;
    width: 25px;
    padding: 0 4px 2px 4px;

    svg {
        margin-bottom: 6px;
    }
}
// link button
.btn-link {
    font-size: 0.8125rem;
    font-weight: 500;
    font-variation-settings: "wght" 500;
}

button.shade {
    background-color: shade($info, 20%) !important;
}
// link button end
// Buttons end
// Separate filters
input.filter {
    height: 38px;

    &:focus,
    &:not(:placeholder-shown) {
        outline-style: solid;
        outline-width: 2px;
        box-shadow: none;
        border: 1px solid #CED4DA;
    }

    &:focus {
        outline-color: rgba($primary, 0.33);
    }

    &:not(:placeholder-shown) {
        outline-color: $primary;
    }
}
// Separate filters end

.splitCellWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 4px !important;
}
.splitCellValue, .splitCellEnv {
    padding: 2px 4px;
}

.splitCellValue {
    &.latest {
        background: rgba($success, 0.05);
    }
    &.earlier {
        background: rgba($warning, 0.2);
    }
}
.splitCellEnv {
    white-space: nowrap;

    &.latest {
        background: rgba($success, 0.05);
    }

    &.earlier {
        background: rgba($warning, 0.2);
    }

    img {
        margin-right: .25rem;
    }
    img:last-child{
        margin-right: 0;
    }
}
.splitBorder {
    border-top: 1px #888 dotted;
}
.siteComment {
    background:lightyellow;
    color: indianred;
}
// Tables
table {
    --bs-table-hover-bg: rgba($active-color, 0.5);
    border: 1px solid $border-color;

    .w-50px {
        width: 50px;
    }

    .w-100px {
        width: 100px;
    }

    .w-160px {
        width: 160px;
    }

    thead {
        font-size: 0.875rem;

        td {
            border: none;
        }

        tr {
            height: 2.5rem;
            vertical-align: middle;

            input[type=text]:focus,
            input[type=text]:not(:placeholder-shown) {
                outline-style: solid;
                outline-width: 2px;
                box-shadow: none;
            }
            //focused filter
            input[type=text]:focus {
                outline-color: rgba($primary, 0.33);
            }
            //applied filter
            input[type=text]:not(:placeholder-shown) {
                outline-color: $primary;
            }
        }

        &.bg-light {
            --bs-table-bg: #E8E8EF;
        }

        th {
            font-weight: 400 !important;
            font-variation-settings: "wght" 400;
            color: $table-header-text-color !important;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    tbody {
        font-size: 0.8125rem;

        tr {
            --bs-table-bg: #fff;
        }

        td {
            color: $table-body-text-color !important;
            border: none;
        }

        td div.btn-toolbar {
            align-content: flex-start;
        }

        td div.empty {
            background-color: #F4F4F6;
        }

        td div.danger {
            color: palevioletred;
        }
    }

    &.table-with-states {
        height: 1px;

        thead {
            &:first-of-type {
                tr:first-child {
                    height: 2.5rem;
                }
            }
        }

        tr {
            //height: 100%;
            td {
                height: inherit;
                padding: 0;

                > div {
                    height: 100%;
                    width: 100%;
                    padding: 0.25rem;
                }

                div.alert-danger {
                    background-color: var(--bs-alert-bg);
                    color: var(--bs-alert-color);
                }
            }
        }

        > tbody > tr {
            height: 100%;
        }
    }

    &.table-editable-cells tr td div {
        padding: 0.375rem 0;
    }

    &.table-with-selection thead tr th:first-child {
        width: 45px;
    }

    &.action-3 thead tr th:last-child {
        width: 97px;
    }

    &.action-2 thead tr th:last-child {
        width: 68px;
    }

    &.table-disabled tbody input {
        pointer-events: none;
        background-color: $input-disabled-bg;
        border-color: $input-disabled-border-color;
        opacity: 1;
    }

    &.table-hover {
        tbody tr:hover {
            background-color: rgba($active-color, 0.5) !important;
            --bs-table-hover-bg: rgba(213,233,255,.5); //#D5E9FF;
            td div.empty {
                background-color: rgba($active-color, 0.5) !important;
            }

            td {
                //color: red;
            }
        }
    }

    .action-btn-col {
        min-width: 70px;
    }

    &.item {
        .action-btn-col {
            min-width: 125px;
        }
    }
}

// Tables end
// Pagination
.pagination {
    flex-wrap: wrap;
    row-gap: 8px;
}

.pagination button.btn-outline-secondary {
    border-color: $border-color;
    color: $list-color !important;
}

.pagination button.btn-outline-secondary,
.pagination .page-link,
.pagination .dropdown-item {
    font-size: 0.8125rem;
}

.pagination .page-link {
    margin-right: 8px;
    color: $list-color;
    border-color: $border-color;
    z-index: 0 !important; //otherwise pagination btn lays on top of an open dropdown menu
}

.pagination button.btn-outline-secondary:hover,
.pagination button.btn-outline-secondary:focus {
    background-color: rgba($active-color, 0.5);
}
// Pagination end
// Modals
.modal-open {
    padding-right: 0 !important;
    overflow: hidden;
}

.modal-content {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.modal-title {
    color: $modal-header-color;
    font-size: $h1-font-size;
    font-weight: 500;
    font-variation-settings: "wght" 500;
}

.modal-header {
    background-color: #fff !important;
    justify-content: space-between;

    &.text-danger .modal-title {
        color: $danger;
    }

    &.cursor {
        cursor: move;
    }
}

.modal-x-btn {
    color: $heading-color;
    background-color: transparent;
}

.modal-x-btn:hover {
    color: rgba($heading-color, 0.5) !important;
    background-color: transparent !important;
}

.modal-body {
    font-size: 0.875rem;
}

.modal-xxl {
    font-size: 0.875rem;
}

@media (min-width: 992px) {
    .modal-xxl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xxl {
        max-width: 1140px;
    }
}

@media (min-width: 1500px) {
    .modal-xl, .modal-xxl {
        max-width: 1440px;
    }
}

@media (min-width: 2000px) {
    .modal-xxl {
        max-width: 1940px;
    }
}
// Modal end

// Forms
.form-group {
    margin-bottom: .25rem !important;

    p.text-danger {
        margin-bottom: 0;
    }

    &.required > label:after, &.required > div > label:after {
        content: " *";
        color: $danger;
    }

    &.default label:after {
        content: " *";
    }

    div.form-group {
        margin-bottom: 0;
    }

    input:focus,
    textarea:focus {
        outline-style: solid;
        outline-width: 2px;
        outline-color: rgba($primary, 0.33);
        box-shadow: none;
        border: 1px solid #CED4DA;
    }
}


.input-disabled {
    background-color: $input-disabled-bg;
}

.form-control, .form-control:focus, .form-check-input {
    background-color: #fff;
}

.form-check-input {
    border: 1px solid rgba(0,0,0,.25);
}

.col-form-label, .form-control {
    font-size: 0.875rem;
}


.modal .form-label, 
.modal .col-form-label {
    color: $info-text-color;
}

.form-control-sm {
    font-size: .875rem;
}

.container-editor {
    min-height: 50px;
    max-height: 360px;
    overflow: auto;
    //padding-left: 0;

    &.disabled {
        background-color: $input-disabled-bg;
        opacity: 1;
    }

    &.editor-lg {
        max-height: 600px;
    }

    &.default {
        min-height: 0;
    }

    textarea {
        outline: 0;
    }

    .monaco-editor {
        position: absolute !important;
        min-height: 35px;
    }
}

.modal {
    .form-check {
        display: flex;
    }
}
.react-datepicker-wrapper{
    width: 100%;
}
// Forms end
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
// Date formats
time {
    white-space: nowrap;
    font-size: 0.75rem;

    &.from-now {
        display: inline-block;
        min-width: 55px;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
// Custom text
.text-custom-light {
    color: $custom-light;
}

.text-major {
    font-weight: 500;
    font-variation-settings: "wght" 500;
    color: $heading-color !important;
}
.text-minor {
    font-size: 0.75rem;
}

div.alert {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
// Info alert box
div.alert-info {
    --bs-alert-color: #5d6265;
    --bs-alert-bg: #e9f4fc;
    --bs-alert-border-color: #d2dce3;
}
// Custom classes
.whitespace-pre-wrap {
    white-space: pre-wrap !important;
}
.whitespace-nowrap {
    white-space: nowrap !important;
}

h4 span.tooltipInfo {
    font-size: 0.875rem;
}

.tooltip-inner {
    max-width: 360px;
    white-space: pre-wrap;
    text-align: left;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
}

.home {
    max-width: 1140px;
}