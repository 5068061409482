.EditorTheme__ltr {
    text-align: left;
}

.EditorTheme__rtl {
    text-align: right;
}

.EditorTheme__paragraph {
    margin: 0;
    position: relative;
}

.EditorTheme__quote {
    margin: 0;
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

/*.EditorTheme__h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
}

.EditorTheme__h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.EditorTheme__h3 {
    font-size: 12px;
    margin: 0;
    text-transform: uppercase;
}*/

.EditorTheme__indent {
    --lexical-indent-base-value: 40px;
}

.EditorTheme__textBold {
    font-weight: bold;
}

.EditorTheme__textItalic {
    font-style: italic;
}

.EditorTheme__textUnderline {
    text-decoration: underline;
}

.EditorTheme__textStrikethrough {
    text-decoration: line-through;
}

.EditorTheme__textUnderlineStrikethrough {
    text-decoration: underline line-through;
}

.EditorTheme__textSubscript {
    font-size: 0.8em;
    vertical-align: sub !important;
}

.EditorTheme__textSuperscript {
    font-size: 0.8em;
    vertical-align: super;
}

.EditorTheme__textCode {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
}

.EditorTheme__hashtag {
    background-color: rgba(88, 144, 255, 0.15);
    border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}

.EditorTheme__link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

    .EditorTheme__link:hover {
        text-decoration: underline;
        cursor: pointer;
    }

.EditorTheme__code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    overflow-x: auto;
    position: relative;
    tab-size: 2;
}

.EditorTheme__code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}

.EditorTheme__table {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: scroll;
    overflow-x: scroll;
    table-layout: fixed;
    width: fit-content;
    margin: 0px 25px 30px 0px;
}

.EditorTheme__tableRowStriping tr:nth-child(even) {
    background-color: #f2f5fb;
}

.EditorTheme__tableSelection *::selection {
    background-color: transparent;
}

.EditorTheme__tableSelected {
    outline: 2px solid rgb(60, 132, 244);
}

.EditorTheme__tableCell {
    border: 1px solid #bbb;
    width: 75px;
    vertical-align: top;
    text-align: start;
    padding: 6px 8px;
    position: relative;
    outline: none;
}

.EditorTheme__tableCellSortedIndicator {
    display: block;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #999;
}

.EditorTheme__tableCellResizer {
    position: absolute;
    right: -4px;
    height: 100%;
    width: 8px;
    cursor: ew-resize;
    z-index: 10;
    top: 0;
}

.EditorTheme__tableCellHeader {
    background-color: #f2f3f5;
    text-align: start;
}

.EditorTheme__tableCellSelected {
    background-color: #c9dbf0;
}

.EditorTheme__tableCellPrimarySelected {
    border: 2px solid rgb(60, 132, 244);
    display: block;
    height: calc(100% - 2px);
    position: absolute;
    width: calc(100% - 2px);
    left: -1px;
    top: -1px;
    z-index: 2;
}

.EditorTheme__tableCellEditing {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}

.EditorTheme__tableAddColumns {
    position: absolute;
    background-color: #eee;
    height: 100%;
    animation: table-controls 0.2s ease;
    border: 0;
    cursor: pointer;
}

.EditorTheme__tableAddColumns:after {
    background-image: url(icons/plus.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.EditorTheme__tableAddColumns:hover,
.EditorTheme__tableAddRows:hover {
    background-color: #c9dbf0;
}

.EditorTheme__tableAddRows {
    position: absolute;
    width: calc(100% - 25px);
    background-color: #eee;
    animation: table-controls 0.2s ease;
    border: 0;
    cursor: pointer;
}

.EditorTheme__tableAddRows:after {
    background-image: url(icons/plus.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

@keyframes table-controls {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.EditorTheme__tableCellResizeRuler {
    display: block;
    position: absolute;
    width: 1px;
    background-color: rgb(60, 132, 244);
    height: 100%;
    top: 0;
}

.EditorTheme__tableCellActionButtonContainer {
    display: block;
    right: 5px;
    top: 6px;
    position: absolute;
    z-index: 4;
    width: 20px;
    height: 20px;
}

.EditorTheme__tableCellActionButton {
    background-color: #eee;
    display: block;
    border: 0;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    color: #222;
    cursor: pointer;
}

.EditorTheme__tableCellActionButton:hover {
    background-color: #ddd;
}

.EditorTheme__characterLimit {
    display: inline;
    background-color: #ffbbbb !important;
}

.EditorTheme__ol1 {
    padding: 0;
    margin: 0;
    list-style-position: outside;
}

.EditorTheme__ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: outside;
}

.EditorTheme__ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: outside;
}

.EditorTheme__ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: outside;
}

.EditorTheme__ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: outside;
}

.EditorTheme__ul {
    padding: 0;
    margin: 0;
    list-style-position: outside;
}

.EditorTheme__listItem {
    margin: 0 32px;
}

.EditorTheme__listItemChecked,
.EditorTheme__listItemUnchecked {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 24px;
    padding-right: 24px;
    list-style-type: none;
    outline: none;
}

.EditorTheme__listItemChecked {
    text-decoration: line-through;
}

.EditorTheme__listItemUnchecked:before,
.EditorTheme__listItemChecked:before {
    content: '';
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0;
    cursor: pointer;
    display: block;
    background-size: cover;
    position: absolute;
}

.EditorTheme__listItemUnchecked[dir='rtl']:before,
.EditorTheme__listItemChecked[dir='rtl']:before {
    left: auto;
    right: 0;
}

.EditorTheme__listItemUnchecked:focus:before,
.EditorTheme__listItemChecked:focus:before {
    box-shadow: 0 0 0 2px #a6cdfe;
    border-radius: 2px;
}

.EditorTheme__listItemUnchecked:before {
    border: 1px solid #999;
    border-radius: 2px;
}

.EditorTheme__listItemChecked:before {
    border: 1px solid rgb(61, 135, 245);
    border-radius: 2px;
    background-color: #3d87f5;
    background-repeat: no-repeat;
}

.EditorTheme__listItemChecked:after {
    content: '';
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 6px;
    width: 3px;
    left: 7px;
    right: 7px;
    height: 6px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
}

.EditorTheme__nestedListItem {
    list-style-type: none;
}

    .EditorTheme__nestedListItem:before,
    .EditorTheme__nestedListItem:after {
        display: none;
    }

.EditorTheme__tokenComment {
    color: slategray;
}

.EditorTheme__tokenPunctuation {
    color: #999;
}

.EditorTheme__tokenProperty {
    color: #905;
}

.EditorTheme__tokenSelector {
    color: #690;
}

.EditorTheme__tokenOperator {
    color: #9a6e3a;
}

.EditorTheme__tokenAttr {
    color: #07a;
}

.EditorTheme__tokenVariable {
    color: #e90;
}

.EditorTheme__tokenFunction {
    color: #dd4a68;
}

.EditorTheme__mark {
    background: rgba(255, 212, 0, 0.14);
    border-bottom: 2px solid rgba(255, 212, 0, 0.3);
    padding-bottom: 2px;
}

.EditorTheme__markOverlap {
    background: rgba(255, 212, 0, 0.3);
    border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}

.EditorTheme__mark.selected {
    background: rgba(255, 212, 0, 0.5);
    border-bottom: 2px solid rgba(255, 212, 0, 1);
}

.EditorTheme__markOverlap.selected {
    background: rgba(255, 212, 0, 0.7);
    border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}

.EditorTheme__embedBlock {
    user-select: none;
}

.EditorTheme__embedBlockFocus {
    outline: 2px solid rgb(60, 132, 244);
}

.EditorTheme__layoutContainer {
    display: grid;
    gap: 10px;
    margin: 10px 0;
}

.EditorTheme__layoutItem {
    border: 1px dashed #ddd;
    padding: 8px 16px;
}

.EditorTheme__autocomplete {
    color: #ccc;
}

.EditorTheme__hr {
    padding: 2px 2px;
    border: none;
    margin: 1em 0;
    cursor: pointer;
}

.EditorTheme__hr:after {
    content: '';
    display: block;
    height: 2px;
    background-color: #ccc;
    line-height: 2px;
}

.EditorTheme__hr.selected {
    outline: 2px solid rgb(60, 132, 244);
    user-select: none;
}

.ContentEditable__root {
    border: 0;
    font-size: 15px;
    display: block;
    position: relative;
    outline: 0;
    padding: 8px 28px 40px;
    min-height: 150px;
}

@media (max-width: 1025px) {
    .ContentEditable__root {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.ContentEditable__placeholder {
    font-size: 15px;
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 8px;
    left: 28px;
    right: 28px;
    user-select: none;
    white-space: nowrap;
    display: inline-block;
    pointer-events: none;
}

@media (max-width: 1025px) {
    .ContentEditable__placeholder {
        left: 8px;
        right: 8px;
    }
}
